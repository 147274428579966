* {
    box-sizing: border-box;
}

body {
    align-items: center;
    justify-content: center;
}
.container {
    width: 100%;
}

.title {
    width: 10%;
    background-color: #124d17;
    font-weight: bolder;
    font-size: small;
    color: white;
    text-align: center;
    display: inline-block;
    position: relative;
    top: -8px;
    padding-top: 6px;
    padding-bottom: 14px;
    margin-right: -5px;
    text-align: center;
    z-index: 9999;
}

.skills {
    position: relative;
    top: 10px;
    width: 0;
    text-align: right;
    background-color: #26d435;
    padding-top: 19px;
    display: inline-block;
    font-weight: bolder;
    border-radius: 5px;
    padding-bottom: 20px;
}

@keyframes fillBar {
    from {
        width: 0;
    }
   to {
        width: attr(w)%;
    }
}

.percentage {
    color: #03a062;
    position: relative;
    top: -5px;
    right: -10px;
}

@media (prefers-reduced-motion: no-preference) {
    .skill-animation {
        animation: fillBar 2.5s 1;
    }
}