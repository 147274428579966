.link {
    padding: 5px 5px 5px 5px;
    color: #03a062;
}

.link:hover {
    background-color: rgb(3, 255, 3);
    color: white;
    text-decoration: underline;
    transition: ease-in-out 2s;
    cursor: pointer;
    box-shadow: inset 200px 0 0 0 green;
}

.navbar {
    transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
}